<template>
	<div
		class="anchor-link"
		@click="scroll"
	>
		<TnButton
			v-if="button"
			:size="componentSize"
			:dark="dark"
			:class="{ block: block, secondary: secondary }"
		>
			{{ text }}
		</TnButton>
		<LinkInline
			v-else
			:text="text"
			:chevronRight="chevronRight"
			:chevronAnimated="chevronAnimated"
			:size="componentSize"
		/>
	</div>
</template>

<script>
import supportsDarkMode from "~/mixins/SupportsDarkMode";
import legacySizeMapping from "~/helpers/mappings/legacySizeMapping";

export default defineNuxtComponent({
	name: "AnchorLink",

	mixins: [supportsDarkMode],

	props: {
		href: {
			type: String,
		},
		button: {
			type: Boolean,
		},
		text: {
			type: String,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		block: {
			type: Boolean,
			default: false,
		},
		chevronRight: {
			type: Boolean,
			default: false,
		},
		chevronAnimated: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: null,
			validator: (value) => {
				return ["xs", "s", "m", "l"].includes(value);
			},
		},
		dark: { type: Boolean, default: false },
	},

	methods: {
		scroll(event) {
			event.stopPropagation();
			const href = this.href.replace("#", "");
			const item = document.querySelector(`.component[id='${href}']`);
			if (item) {
				item.scrollIntoView({ behavior: "smooth", block: "center" });
				this.$store.dispatch("setAutoScrolling", true);

				setTimeout(() => {
					this.$store.dispatch("setAutoScrolling", false);
				}, 1000);
			}
		},
	},

	computed: {
		componentSize() {
			return legacySizeMapping(this.size);
		},
	},
});
</script>

<style lang="scss" scoped>
.anchor-link {
	width: 100%;
}
</style>
